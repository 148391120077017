@import url("https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Urbanist", sans-serif;
}


.containerApp{
  width: 60%;
  height: 100%;
}

.containerbodyApp{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.container-banner{
  width: 100%;
  height: 100%;
  position: relative;
  background: linear-gradient(rgb(0, 0, 0),white);
}
.container-banner::before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(rgba(0, 0, 0, 0),rgba(255, 255, 255, 0.593));
}


.central-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 1rem;
  width: 100%;
  height: 100%;
}



body {
  background-repeat:no-repeat;
  background-size:auto;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.shadowCard {
  margin-bottom: 1vh !important;
  height: 100%;
  width: 100%;
  background-color: #CB0611;
}



.bannerPng{
  width: 100%;
  background: linear-gradient(rgb(255, 255, 255),rgb(0, 0, 0));

}

.logoTracking {
  width: 40%;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7478%)
  hue-rotate(52deg) brightness(107%) contrast(97%);
}

.divlogo {
  display: flex;
  justify-content: center;
}



.inputCustom {
  border-style: none;
  font-size: 22px;
  border-radius: 15px;
  padding-left: 1rem;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  outline-style: none;
  color: #000000a0;
}
.inputCustom::placeholder {
  color: #00000032;

  opacity: 1; /* Firefox */
}

.buttonCustom {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  background: linear-gradient(90deg, #b18fb323 0%, #000000 100%);
  padding: 5px 30px;
  box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
  border-radius: 50px;
  transition: 1000ms;
  transform: translateY(0);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border-style: none;
}

.buttonCustom:hover {
  transition: 500ms;
  padding: 5px 50px;
  transform: translateY(-0px);
  background: linear-gradient(90deg, #000000 0%, #b18fb323 100%);
  color: #fff;
}

.footerApp{
  color: rgb(117, 117, 117);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerInfo{
  display: flex;

}

.footerContact{
  border-left:rgba(226, 222, 222, 0.863) ;
  border-color: rgba(226, 222, 222, 0.863);
  border-right-style: solid;
  padding: 0 0.5rem;
  border-width: 0.5px;
}

.footerAddress{
  padding: 0 0.5rem;
  border-width: 0.5px;
}

.footerIntegrations{
  color: rgb(117, 117, 117);
  display: flex;
  justify-content: center;
}

ul {
  columns: 2; /* Número de columnas */
  -webkit-columns: 3;
  -moz-columns: 3;
  padding: 0.5rem !important;
}

.hrEnd{
  margin-bottom: 1rem;
  border-color: rgb(117, 117, 117) !important;
  width: 60%;
}

li > img{
  width: 20px;
  height: 20px;
  border: 20px;
  border-radius: 50%;
  margin-left: 0.5rem ;

}
/* ---------------------contenedor de la informacion de la orden --------------------------------- */


.movement{
  /* border-style: solid; */
  /* border-color: #000000; */
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 2px;
  width: 100%;
  background: transparent;
  background-color: #F8D7DA;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  color:#58151C;
}

b{
  color:#58151C;
}

.text-detail{
  text-align: start;
  color: #58151C;
  width: 60%;
}

.espacing-hr{
  border-color:#58151C;
  margin: 0px;
  
}

.history-order{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: calc(100vh - 38vh);
  overflow: scroll;
  
}

.image-evidence{
  height: 100%;
  transition: 500ms;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.invisible{
  display: none;
}

.image-resize{
  width: 4.5rem;
  height: 8rem;
  transition: 500ms;
  transform: scale(3);
  position: absolute;
  left: 50%;
  top: 70%;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.history-order::-webkit-scrollbar {
  display: none;
  position: relative;
}

/* .image-evidence:hover{
  transform: scale(5);
  position: absolute;
  left: 50%;
  top: 50%;

} */



.button-close{
  background-color: rgba(0, 0, 0, 0.603);
  color: white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 57%;
  right: 40vw;
  border-style: none;
  font-weight: 700;
  box-shadow: 2px 2px 6px white;
}


@media (max-width: 800px) {


  .container-order-info{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    left: 10vw;
  }
}




@media ( max-width: 492px) {

  .inputCustom {
    max-width: 10rem;
    font-size: small;
  }

  .buttonCustom{
    max-width: 5rem;
    max-height: 1rem;
    font-size: small;
    padding: 10px;

  }

  ul {
    columns: 1; /* Número de columnas */
    -webkit-columns: 1;
    -moz-columns: 1;
    padding: 0.5rem !important;
  }

  .footerInfo{
    display: table-column
  }

  .footerContact{
   border: none;
  }

  .containerApp{
    width: 95%;
    height: 100%;
  }
  }

  @media (min-width: 492px) and (max-width: 1000px) {

    ul {
      columns: 1; /* Número de columnas */
      -webkit-columns: 1;
      -moz-columns: 1;
      padding: 0.5rem !important;
    }

    .logoTracking{
      display: none;
    }
    
    .text-logo{
      text-align: center;
      font-size: medium; 
    }
    .footerInfo{
      display: table-column;
      
    }
    .footerContact{
      border: none;
     }
    }

